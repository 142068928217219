/*
@import 'scss/base/_variables.scss';
@import 'scss/components/_buttons.scss';
@import 'scss/components/_fonts.scss';
@import 'scss/components/_forms.scss';
*/
$blue: #1aa3ff;
$green: #45e685;
$yellow: #ecc30b;
$red: #eb4a6f;

$white: #f6fafd;
$gray-lighter: #ebeef3;
$gray-light: #d7e0e7;
$gray: #7f92a3;
$gray-dark: #536476;
$gray-darker: #464953;
$gray-darkest: #363940;
$black: #2f3137;

@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

.MuiFormControlLabel {
  &-root {
    margin-bottom: 10px;

    &.checkboxButton {
      position: relative;
      border-radius: 5px;
      justify-content: space-between;

      .MuiCheckbox-root {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        border: 2px solid $gray;
        border-radius: 5px;
      }

      .MuiTypography-root {
        position: absolute;
        left: 20px;
        font-weight: 600;
      }

      &:hover {
        background-color: transparent;

        .MuiCheckbox-root {
          border-color: $gray-lighter;
          background-color: transparent;
          animation-name: border_fade;
          animation-duration: 0.2s;
          animation-timing-function: linear;
          animation-iteration-count: 1;

          @keyframes border_fade {
            0% {
              border-color: $gray;
            }

            100% {
              border-color: $gray-lighter;
            }
          }
        }
      }
    }

    &.radioLargeInfo {
      position: relative;
      margin-left: 0;
      margin-right: 0;

      .MuiButtonBase-root {
        display: block;
        width: 100%;
        border: 2px solid $gray;
        border-radius: 5px;
        justify-content: flex-start;
        padding: 20px;

        &:hover {
          border-color: $gray-lighter;
        }

        &.Mui-checked {
          border-color: $blue;
        }
      }

      .MuiTypography-root {
        position: absolute;
        left: 0;
        top: 20px;
        left: 7rem;
        font-size: 1.75rem;
        font-weight: 600;
      }

      .MuiIcon-fontSizeLarge {
        font-size: 4.75rem;
        color: $gray-dark;
      }
    }
  }

  &-labelPlacementStart {
    &.checkboxButton {
      margin-left: 0;
      margin-right: 0;

      .MuiIconButton-root {
        svg {
          opacity: 0;
          transition: opacity 0.2s;
        }

        &.Mui-checked {
          border-color: $blue;

          svg {
            opacity: 1;
          }

          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }
}

hr {
  border-color: rgba($white, 0.2);
}

a,
a:link {
  color: $blue;
}

.MuiFormControl-root {
  border-radius: 5px;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
  border-radius: 5px !important;
}

.MuiFormLabel-root.Mui-focused,
.MuiFormLabel-filled {
  display: none !important;
}

.MuiTableCell-root.MuiTableCell-body:empty {
  border: none;
}

.centered {
  text-align: center;
}

.loading-spinner-left {
  margin-top: -16px;
  margin-bottom: -16px;
  margin-left: 8px;
}

.MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-formControl {
  border-radius: 5px;
}

.MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-formControl:before {
  border: none;
}

div.MuiInputBase-root.MuiInput-root.MuiInput-underline::before {
  border: none;
}

div.MuiInputBase-root.MuiInput-root.MuiInput-underline::after {
  border: none;
}

.MuiBox-root>a,
.MuiGrid-root>a {
  text-decoration: none;
}

a[disabled] {
  opacity: 0.5;
}

em {
  font-weight: bold;
  font-style: italic;
  text-decoration: underline;
}

header.MuiAppBar-root {
  box-shadow: none;
}

// stripe styles
.StripeElement {
  box-sizing: border-box;
  color: green;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

input.InputElement {
  color: black;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: $red;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}